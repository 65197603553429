import styles from "./path-info.module.css";
import { ReactComponent as CloseIcon } from "../../assets/images/close.svg";
import { PoiResponse } from "../../interfaces/backend/poi.interface";
import { PROPS } from "../../constants/map.constants";
import { useMapContext } from "../../hooks/use-map-context";
import { replaceWithBr } from "./utils";

type Props = {
  data: PoiResponse;
  onClose: () => void;
  isStandAlone: boolean;
};
export const PoiInfo = ({ data, onClose, isStandAlone }: Props) => {
  const { useEnglish } = useMapContext();
  const description =
    useEnglish && data.description_en ? data.description_en : data.description;
  const name = useEnglish && data.name_en ? data.name_en : data.name;

  const categoryName =
    useEnglish && data.pointOfInterestCategory?.name_en
      ? data.pointOfInterestCategory?.name_en
      : data.pointOfInterestCategory?.name;
  const Icon =
    data?.pointOfInterestCategory?.identifier &&
    PROPS.POIS[
      data.pointOfInterestCategory?.identifier as keyof typeof PROPS.POIS
    ].icon;

  return (
    <div
      className={
        isStandAlone
          ? styles.pathInfoContainerStandAlone
          : styles.pathInfoContainer
      }
      style={{ height: isStandAlone ? "400px" : "200px" }}
      id="sheet"
    >
      <div className={styles.header}>
        <div className={styles.handleWrapper} id="handle">
          <div className={styles.dragHandle} />
        </div>
        <div
          className={styles.headerInfoSection}
          style={{ gap: 8, marginLeft: 12, alignItems: "flex-start" }}
        >
          {Icon && (
            <div style={{ alignItems: "center" }}>
              <img
                src={Icon}
                alt="Ikon"
                style={{ marginTop: isStandAlone ? "50%" : 0 }}
              />
            </div>
          )}
          <div className={styles.headerRightCol}>
            <h1 className={isStandAlone ? styles.title : styles.titleMobile}>
              {name}
            </h1>
            <div style={{ fontSize: 14, color: "#666666" }}>{categoryName}</div>
            {data.url && (
              <a
                href={data.url}
                target="_blank"
                rel="noreferrer"
                style={{ fontSize: 14, color: "#4684A5" }}
              >
                {data.url}
              </a>
            )}
          </div>

          <CloseIcon className={styles.closeButton} onClick={onClose} />
        </div>
        {description && (
          <div
            className={styles.snowmobilePathDescription}
            dangerouslySetInnerHTML={{
              __html: replaceWithBr(description),
            }}
          />
        )}
      </div>
    </div>
  );
};
